<script setup>
import { Icon } from "@iconify/vue";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed } from "vue";

import { getContracts, updateContract, getContractDoc } from "@/http/contract";
import { ElNotification, ElMessage, ElMessageBox } from "element-plus";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import DownloadModal from "@/components/modal/download-modal.vue";

const router = useRouter();
const store = useStore();
const user = computed(() => store.getters["user/profile"]);
const loading = ref(false);
const contrats = ref([]);
const page = ref(1);
const perPage = ref(10);
const search = ref(null);
const category_id = ref(null);
const lastPage = ref(null);
const from = ref(null);
const to = ref(null);
const total = ref(null);
const currentPage = ref(null);
const previewData = ref({
  id: "",
  contract_content: "",
  name: "",
});
const downloadRef = ref();
const openDownloadModal = ref();
const doc_type = ref("pdf");
const previewDialogVisible = ref(false);
const editDialogVisible = ref(false);
const baseURL = localStorage.getItem("BASE_URL_OPP");
const editor = ref();
onMounted(() => {
  fetchContracts();
});
const fetchContracts = async () => {
  loading.value = true;
  await getContracts(page.value, perPage.value, search.value, category_id.value)
    .then((response) => {
      loading.value = false;
      lastPage.value = response.last_page;
      from.value = response.from;
      to.value = response.to;
      total.value = response.total;
      currentPage.value = response.current_page;
      contrats.value = response.data;
    })
    .catch(() => {
      loading.value = false;
      ElNotification({
        title: "Erreur",
        message: "Oops. Les contrats n'ont pas pu être chargés",
        type: "error",
      });
    });
};

const tempData = ref(null);
const handleDownload = async () => {
  await getContractDoc(tempData.value?.id, doc_type.value)
    .then((doc) => {
      docDownloader(doc, tempData.value);
    })
    .catch(() => {
      ElNotification({
        title: "Erreur",
        message: "Erreur de téléchargement",
        type: "error",
      });
    });
};

const docDownloader = (doc, contractData) => {
  const url = window.URL.createObjectURL(new Blob([doc]));
  const link = document.createElement("a");
  link.href = url;
  const name =
    contractData?.contract?.name.split(" ").join("_") + "." + doc_type.value;
  link.setAttribute("download", name);
  downloadRef?.value.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

const handlePreview = (contractData) => {
  let value = JSON.parse(contractData.contract_content);
  let converter;
  if (typeof value == "string") {
    converter = new QuillDeltaToHtmlConverter(JSON.parse(value).ops, {});
  } else {
    converter = new QuillDeltaToHtmlConverter(value.ops, {});
  }
  const html = converter.convert();
  previewData.value.contract_content = html;
  previewData.value.name = contractData.contract.name;
  previewDialogVisible.value = true;
};

const handleEdit = (contractData) => {
  previewData.value.name = contractData.contract.name;
  previewData.value.contract_content = contractData.contract_content;
  previewData.value.id = contractData.id;
  editDialogVisible.value = true;
  handleEditorReady();
};

const handleEditorReady = () => {
  const value = JSON.parse(previewData.value.contract_content);
  if (typeof value == "string") {
    editor.value.setContents(JSON.parse(value));
  } else {
    editor.value.setContents(value);
  }
};

const handleEditDialogClose = async (done) => {
  editor.value = null;
  done();
  await updateContract(previewData.value.id, {
    content: JSON.stringify(previewData.value.contract_content),
  })
    .then(() => {
      ElMessage("Mise a jour effectuée avec succès");
      fetchContracts();
    })
    .catch(() => {
      ElMessage("Erreur lors de la mise a jour");
    });
};

const checkOffer = () => {
  if (
    user.value?.active_user_offers &&
    user.value?.active_user_offers[0]?.offer?.name == "Partner"
  ) {
    console.log;
  } else {
    ElMessageBox.confirm(
      "Vous devez souscrire à l'offre Partner avant de pouvoir créer des contrats.",
      "",
      {
        confirmButtonText: "Souscrire",
        cancelButtonText: "Annuler",
        type: "warning",
      }
    )
      .then(() => {
        router.push("/abonnement");
      })
      .catch(() => {});
  }
};
</script>

<template>
  <!-- Breadcrumb -->
  <div class="d-flex justify-content-between align-items-center mb-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb m-0">
        <li class="breadcrumb-item">
          <a href="#">Opencontrat</a>
        </li>
        <li class="breadcrumb-item active text-dark" aria-current="page">
          Contrats ( {{ total }} )
        </li>
      </ol>
    </nav>
    <SearchInput v-model="search" @onSearch="fetchContracts" />
  </div>
  <!-- End Breadcrumb -->
  <Loader v-if="loading" />
  <div v-else class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3">
    <div v-for="(item, index) in contrats" :key="index" class="col">
      <CardContrat
        :title="item?.contract?.name"
        :price="item?.contract?.price"
        :image="baseURL + 'storage/' + item?.contract?.image"
      >
        <button
          class="btn btn-link btn-sm text-primary"
          @click="
            tempData = item;
            openDownloadModal = true;
          "
        >
          Télécharger
        </button>
        <button class="btn btn-link btn-sm text-info" @click="handleEdit(item)">
          Editer
        </button>
        <button
          class="btn btn-link btn-sm text-secondary"
          @click="handlePreview(item)"
        >
          Visualiser
        </button>
      </CardContrat>
    </div>
  </div>
  <div ref="downloadRef"></div>
  <div
    v-if="!lastPage == 1"
    class="d-flex justify-content-between align-items-center m-3"
  >
    <ul class="pagination">
      <li>
        <p aria-label="Previous">
          <span aria-hidden="true">«</span>
        </p>
      </li>
      <template v-for="(item, index) in lastPage" :key="index">
        <li :class="item == currentPage ? 'active' : ''">
          <p @click="(page = item), fetchContracts()" class="pointer">
            {{ item }}
          </p>
        </li>
      </template>
      <li>
        <p aria-label="Next">
          <span aria-hidden="true">»</span>
        </p>
      </li>
    </ul>
    <div class="">
      <em>Affichage de {{ from }} à {{ to }} (sur {{ total }} éléments)</em>
    </div>
  </div>

  <div
    v-if="user?.active_user_offers[0]?.offer?.name == 'Partner'"
    class="pointer"
    style="position: absolute; bottom: 5%; right: 2%; z-index: 9000"
  >
    <Icon
      icon="akar-icons:circle-plus-fill"
      width="50"
      height="50"
      color="#FF0101"
      @click="checkOffer()"
    />
  </div>

  <el-dialog
    v-model="previewDialogVisible"
    :title="previewData.name"
    center
    fullscreen
  >
    <div v-html="previewData.contract_content"></div>
  </el-dialog>

  <el-dialog
    v-model="editDialogVisible"
    :title="previewData.name"
    :before-close="handleEditDialogClose"
    center
    fullscreen
  >
    <QuillEditor
      ref="editor"
      theme="snow"
      toolbar="full"
      @ready="handleEditorReady"
      placeholder="Commencer la saisie ici"
      v-model:content="previewData.contract_content"
    />
  </el-dialog>
  <DownloadModal
    v-if="openDownloadModal"
    @close="
      openDownloadModal = false;
      handleDownload();
    "
  >
    <template v-slot:header>
      <div class="text-primary">
        Quelle version du contrat désirez-vous télécharger ?
      </div>
    </template>
    <template v-slot:body>
      <div class="d-flex flex-column">
        <el-radio v-model="doc_type" label="pdf" size="large"
          >Version pdf</el-radio
        >
        <el-radio v-model="doc_type" label="doc" size="large"
          >Version word</el-radio
        >
      </div>
    </template>
  </DownloadModal>
</template>

<style scoped></style>

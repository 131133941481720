import request from './request'

export function getContracts() {
  return request({
    url: 'user-contracts',
    method: 'get'
  })
}

export function updateContract(id, data) {
  return request({
    url: 'user-contracts/' +id,
    method: 'put',
    data
  })
}

export function getContractDoc(id, type) {
  return request({
    url: "user-contracts/" + id + "/download/"+type,
    method: 'get',
    responseType: 'blob'
  })
}